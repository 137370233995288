<template>
  <div class="specialist-card-small">
    <div class="specialist-card-small__img">
      <UIPictureTag
        :image-webp="`${url}${image.webp}`"
        :image-seo="image_seo"
        :image-original="`${url}${image.source}`"
        is-visible
      />
    </div>
    <div class="specialist-card-small__content">
      <div class="specialist-card-small__type" v-if="subtitle">{{ subtitle }}</div>
      <nuxt-link
        :to="{ name: 'home', params: { chapters: useSlugTransform(full_slug) } }"
        class="specialist-card-small__name"
      >
        {{ title }}
      </nuxt-link>
      <div class="specialist-card-small__desc">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './styles.scss';
import type { DoctorsCard } from '~/types/pages';
import { useSlugTransform } from '~/composables/useSlugTransform';
import { useRuntimeConfig } from '#imports';
const url = useRuntimeConfig().public.siteUrl;

interface Props extends DoctorsCard {
  subtitle: string;
}

defineProps<Props>();
</script>

<style scoped></style>
